import '@css/app.pcss'
import LazyLoad from "vanilla-lazyload";
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { faAngleRight, faArrowRight, faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faFacebookSquare, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

document.querySelector('.icon-scroll')?.addEventListener('click', function () {
    const elemRect = document.querySelector('.icon-scroll')?.getBoundingClientRect();

    if (elemRect && elemRect.top > window.innerHeight * 0.5) {
        window.scrollTo({
            top: elemRect.top + 50,
            behavior: 'smooth'
        });
    } else {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
});

new LazyLoad({
    elements_selector: '.lazy',
    threshold: 0,
    callback_loaded: (element: HTMLElement) => {
        element.classList.add('loaded');
    }
});

// We are only using the user-astronaut icon
library.add(
    faAngleRight,
    faArrowRight,
    faBars,
    faTimes,
    faFacebookSquare,
    faInstagram,
    faLinkedinIn
)

// Replace any existing <i> tags with <svg> and set up a MutationObserver to
// continue doing this as the DOM changes.
dom.watch()


let isPositioned = false;

function adjustFocalPoint() {
    const img = document.getElementById('headerImage') as HTMLImageElement | null;
    if (!img) return;
    
    // Use modern loading attribute
    if (!img.complete) {
        img.addEventListener('load', () => adjustFocalPoint());
        return;
    }
    
    const container = img.parentElement?.parentElement;
    if (!container) return;
    
    const containerAspect = container.offsetWidth / container.offsetHeight;
    const imageAspect = img.naturalWidth / img.naturalHeight;
    
    // If aspects are similar enough, we can use simple object-fit/object-position
    if (Math.abs(containerAspect - imageAspect) < 0.1) {
        if (!isPositioned) {
            img.classList.remove('invisible');
            img.classList.add('fade-in');
            isPositioned = true;
        }
        return;
    }
    
    const focalX = parseFloat(img.getAttribute('data-focal-x') || '0.5');
    const focalY = parseFloat(img.getAttribute('data-focal-y') || '0.5');
    
    // Calculate distances from focal point to edges
    const distanceToLeft = focalX * img.naturalWidth;
    const distanceToRight = (1 - focalX) * img.naturalWidth;
    const distanceToTop = focalY * img.naturalHeight;
    const distanceToBottom = (1 - focalY) * img.naturalHeight;
    
    // Calculate minimum scale needed
    const scaleX = Math.max(
        container.offsetWidth / 2 / distanceToLeft,
        container.offsetWidth / 2 / distanceToRight
    );
    const scaleY = Math.max(
        container.offsetHeight / 2 / distanceToTop,
        container.offsetHeight / 2 / distanceToBottom
    );
    
    const scale = Math.max(scaleX, scaleY) * 1.02;
    
    const scaledWidth = img.naturalWidth * scale;
    const scaledHeight = img.naturalHeight * scale;
    
    // Center the focal point
    const left = (container.offsetWidth / 2) - (distanceToLeft * scale);
    const top = (container.offsetHeight / 2) - (distanceToTop * scale);
    
    // Apply positioning
    img.style.width = `${scaledWidth}px`;
    img.style.height = `${scaledHeight}px`;
    img.style.left = `${left}px`;
    img.style.top = `${top}px`;
    
    // Show image after initial positioning
    if (!isPositioned) {
        requestAnimationFrame(() => {
            img.classList.remove('invisible');
            img.classList.add('fade-in');
            isPositioned = true;
        });
    }
}

// Use modern ResizeObserver with cleanup
const resizeObserver = new ResizeObserver((entries) => {
    requestAnimationFrame(() => {
        adjustFocalPoint();
    });
});

// Cleanup function for better memory management
function cleanup() {
    const container = document.querySelector('.image-container');
    if (container) {
        resizeObserver.unobserve(container);
    }
}

// Modern event listener setup
window.addEventListener('load', () => {
    const container = document.querySelector('.image-container');
    if (container) {
        resizeObserver.observe(container);
    }
    adjustFocalPoint();
});

// Cleanup on page unload
window.addEventListener('unload', cleanup);